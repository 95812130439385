export function commons(){
      $('.modal').modaal({
          type: 'ajax',	// コンテンツのタイプを指定
          animation_speed: '500', 	// アニメーションのスピードをミリ秒単位で指定
          background: '#fff',	// 背景の色を白に変更
          overlay_opacity: '0.9',	// 背景のオーバーレイの透明度を変更
          fullscreen: 'true',	// フルスクリーンモードにする
          background_scroll: 'true',	// 背景をスクロールさせるか否か
          loading_content: 'Now Loading, Please Wait.'	// 読み込み時のテキスト表示
      });
	
      var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });
      var galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: {
          swiper: galleryThumbs
        },
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true
        },
      });
	
	
          var canvas = document.getElementById('addMap') ;
          var latlng = new google.maps.LatLng(35.6970121, 140.0620768);
          var mapOptions = {
              zoom: 17 ,
              center: latlng ,
              scrollwheel: false
          };
          var map = new google.maps.Map(canvas,mapOptions) ;
          var markers = [] ;
          markers[0] = new google.maps.Marker({
              map: map,
              position: new google.maps.LatLng(35.6970121, 140.0620768),
              title : "西精機"
          });
          var mapStyle = [
            {
                "stylers": [
                  { "saturation": -100 }
                ]
            }
          ];
          var mapType = new google.maps.StyledMapType(mapStyle);
          map.mapTypes.set('GrayScaleMap', mapType);
          map.setMapTypeId('GrayScaleMap');
	

    //バーガーメニュー
    $("#HEADER .global").on("click", function () {
        if ($(window).width() < 769) {
            $(this).children("ul").stop().slideToggle(400);
            if ($(this).is('.addClose')) {
				$("body").removeClass('overlayMenu');
                $(this).removeClass('addClose');
            } else {
				$("body").addClass('overlayMenu');
                $(this).addClass('addClose');
            }
        }
    });


    const target = $("#firstview");
    const photo = new Array();
    for(let i=0; i < target.find(".hide img").length; i++){
        photo[i] = target.find(".hide img").eq(i).attr("src");
    }
    const photos = Object.entries(photo).map(([src, value]) => ({'src': value}));
    target.vegas({
        overlay: false,
        timer:false,
        transitionDuration: 2000,
        delay: 10000,
        animation: 'kenburns',
        animationDuration: 20000,
        loop:false,
        slides: photos
    });
   }