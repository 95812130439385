export function vues(){
    new Vue({
        el: "#VUE",
        data: {
            flowList:[]
        },
        created() {
         	// 面倒だからjsonから抽出
            axios.get('/assets/flow.json')
            .then( response => {
                this.flowList = response.data;
            })
            .catch(error => {
                console.log('error');
            });
        }
    });
}
